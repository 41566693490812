<template>
  <div>
    <div class="product">
      <div class="product-input">
        <img class="input-img" src="../../../static/img/sousuo.png" alt="" />
        <input
          v-model="searchLike"
          class="input-sty"
          placeholder="请输入小区名称或地址"
          type="text"
        />
        <button class="pro-but" @click="search">搜索</button>
        <!-- <div @click="clickMap">地图找房</div> -->
      </div>
      <div class="area-product">
        <div class="area-style">
        <span class="area-name">区域：</span>
        <div :class="isArea==-1?'area-color check-area':'area-color'" @click="areaAll">不限</div>
        <div v-for="(item,index) in areaList1" :key="index">
          <div :class="isArea==item.id?'area-color check-area':'area-color'" @click="showDicts(item)">{{item.name}}</div>
          </div>
        </div>
        <div class="area-style dicts-sty">
          <span v-show="isAreaName" class="area-name">板块：</span>
          <div v-for="(item,index) in areaList2" :key="index">
            <div :class="isArea1==item.id?'area-color check-area':'area-color'"  @click="clicked(item)">{{item.name}}</div>
          </div>
        </div>
      </div>
      <div
        class="product-con"
        v-for="(item, index) in productList"
        :key="index"
      >
        <div class="con-cont">
          <div class="con-cont-left">
            <div class="con-img">
              <img class="con-img-img" style="width: 100%;" :src="item.fileUrl" alt="" />
              <div class="img-text" v-if="item.auctionStatus==0">正在拍卖</div>
              <div class="img-text" v-else-if="item.auctionStatus==1">待定</div>
              <div class="img-text" v-else-if="item.auctionStatus==2">已成交</div>
            </div>
            <div class="cont-left-text">
              <div class="left-text-tit">{{ item.communityName }}</div>
              <div class="tit-adress">{{ item.county }} - {{ item.cdb }}</div>
              <div class="tit-sum">
                <div>面积：{{ item.area }}㎡</div>
                <div>拍卖时间：{{ item.saleTime }}</div>
                <div>{{item.clickCount}}人查看</div>
                <div class="tag-content">
                  <div class="tag-sty" v-for="(item,index) in tagList" :key="index">{{item}}</div>
                </div>
                <div class="max-mo">
                  <div class="right-price">{{ item.price }}<span>万元起</span></div>
                  <button class="right-but" @click="getDetails(item.id)">
                    查看详情
                  </button>
                </div>
                <!-- <div>地址：{{item.address}}</div> -->
              </div>
            </div>
          </div>
          <div class="con-cont-right">
            <div class="right-price">{{ item.price }}<span>万元起</span></div>
            <button class="right-but" @click="getDetails(item.id)">
              查看详情
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="loading-more" v-if="pageNum<totalPage" @click="loadingMore()">
      加载更多
      <!-- <span class="more-span" @click="loadingMore()">加载更多</span> -->
    </div>
    <div class="common-page">
      <Page
        v-if="productList.length > 0"
        class="page-con"
        :totalData="totalSize"
        :sizePage="pageSize"
        :currentPage="pageNum"
        @change="pageChange"
      ></Page>
    </div>
  </div>
</template>
<script>
import Page from "@/components/page.vue";
export default {
  components: { Page },
  data() {
    return {
      // searchLike:'',
      isAreaName:false,
      isArea:-1,
      isArea1:null,
      sort: 1,
      pageNum: 1, //当前页
      pageSize: 10, //每页数量
      totalSize: 0, //数据总条数
      totalPage:0,//总页数
      clickCount:'',
      searchLike: "",
      cdb:"",
      county:"",
      productList: [],
      areaList1:[],
      areaList2:[],
      tagList:[]
    };
  },
  created() {
    // this.getList();
  },
  mounted() {
    this.getList();
    this.showCounty()

  },
  methods: {
    //地图找房
    clickMap(){
      // this.$router.push('/home')
    },
    //移动端加载更多
    loadingMore(){
      this.pageNum++
      console.log(this.pageNum);
      this.getList(true);
    },
    areaAll(){
      this.county=''
      this.cdb=''
      this.isArea=-1
      this.isAreaName=false
      this.getList();
    },
    showDicts(item){
       this.isArea=item.id
       this.county=item.name
       this.pageNum=1
       this.cdb=''
       this.isAreaName=true
       this.getList()
    },
    clicked(item){
      this.isArea1=item.id
      this.cdb=item.name
      this.pageNum=1
      this.getList()
    },
    showCounty(){
      this.$http.get(this.$api.showCounty,{id:1}).then(res=>{
        console.log(res);
        this.areaList1=res.data
      })
    },
    getList(isMobilePhone) {
      let data = {
        sort: this.sort,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        searchLike: this.searchLike,
        cdb:this.cdb,
        county:this.county
      };
      this.$http.post(this.$api.housesUrl, data).then((res) => {
        console.log(res);
        this.totalSize = res.data.pageResult.totalSize;
        this.totalPage = res.data.pageResult.totalPages;
        if(isMobilePhone){
          this.productList = this.productList.concat(res.data.pageResult.content);
        }else{
          this.productList = res.data.pageResult.content;
          // if(this.productList==''){
          //   this.productList.map(x=>{
          //   this.tagList=x.label.split(',')
          // })
          // }
        }
        this.areaList2=res.data.dicts
        // .toString()
      });
    },
    getDetails(id) {
      console.log(id);
      //统计点击量
      this.$http.get(this.$api.countClick,{houseId:id}).then(res=>{
          this.clickCount=res.data
      })
      this.$router.push({ path: "/index/productData", query: { id: id } });
    },
    pageChange(pageSize, pageNum) {
      //  this.pageNum=page
      this.pageSize = pageSize;
      this.pageNum = pageNum;
      this.getList();
    },
    search() {
      this.pageNum = 1;
      this.getList();
    },
  },
};
</script>
<style scoped>
@media screen and (min-width: 750px) and (max-width: 1920px) {
  .area-product{
    width: 1190px;
    margin: auto;
    background: #fff;
  }
  .area-name{
    padding: 5px;
    font-size: 16px;
    font-weight: bold;
    color: black;
  }
  .area-style{
    margin: auto;
    padding: 35px 35px 0 35px;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
  }
  .dicts-sty{
    /* margin-top: 10px; */
    padding: 10px 35px 35px 35px;
  }
  .area-color{
    padding:5px;
    font-size: 14px;
    color: #545454;
  }
  .check-area{
    color: red;
  }
  input:focus {
    outline: none;
  }
  .product-input {
    width: 1190px;
    position: relative;
    margin: 20px auto;
    text-align: left;
  }
  .input-sty {
    width: 385px;
    line-height: 36px;
    padding-left: 35px;
    border: 1px solid #ccc;
  }
  .input-img {
    width: 30px;
    position: absolute;
    top: 4px;
    left: 3px;
  }
  .pro-but {
    width: 83px;
    height: 38px;
    line-height: 36px;
    border: none;
    color: #ffffff;
    background-color: #e86300;
  }
  .product-con {
    width: 1190px;
    height: 260px;
    margin: 20px auto 0;
    background: #fff;
  }
  .con-cont {
    display: flex;
    justify-content: space-between;
    padding: 35px;
  }
  .con-cont-left {
    display: flex;
    width: 100%;
  }
  .con-img {
    width: 250px;
    height: 185px;
    position: relative;
  }
  .img-text {
    position: absolute;
    bottom: 20px;
    right: 0px;
    padding: 6px 15px;
    background: linear-gradient(to right, #da3a02 0%, #ffffff 100%);
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    color: #fff;
  }
  .cont-left-text {
    margin-left: 40px;
    text-align: left;
  }
  .left-text-tit {
    font-size: 30px;
    cursor: pointer;
  }
  .tit-adress {
    width: 135px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    padding: 5px 30px;
    border-radius: 10px;
    background: #eeeeee;
  }
  .tit-sum {
    width: 400px;
    line-height: 25px;
    border-top: 2px dotted #eee;
    margin-top: 15px;
    padding-top: 2px;
    font-size: 14px;
  }
  .tag-content{
    display: flex;
  }
  .tag-sty{
    color: #849aae;
    font-size: 12px;
    line-height: 30px;
    padding: 0 11px;
    margin-right: 6px;
    background-color: #f4f7f9;
  }
  .con-cont-right {
    width: 300px;
    padding: 35px 110px 0 0;
  }
  .max-mo{
    display: none;
  }
  .right-price {
    font-size: 40px;
    color: #ec6c00;
  }
  .right-price span {
    font-size: 16px;
    color: #ec6c00;
  }
  .right-but {
    width: 170px;
    margin-top: 20px;
    height: 42px;
    border: none;
    border-radius: 25px;
    background-color: #545454;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    color: #fff;
  }
  .common-page {
    display: block;
    width: 1190px;
    margin: auto;
    position: relative;
    height: 150px;
  }
  .page-con {
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
  }
  .loading-more{
    display: none;
  }
  
}
@media screen and (min-width: 10px) and (max-width: 750px) {
   .area-style{
    width: 90%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
  }
  .area-name{
    padding: 5px;
    font-size: 16px;
    font-weight: bold;
    color: black;
  }

  .dicts-sty{
    margin-top: 10px;
    font-size: 14px;
    color: #545454;

  }
  .area-color{
    padding:5px;
  }
  .check-area{
    color: red;
  }
  input:focus {
    outline: none;
  }
  .product-input {
    width: 100%;
    position: relative;
    margin: 20px;
    text-align: left;
  }
  .input-sty {
    width: 55%;
    line-height: 36px;
    padding-left: 35px;
    border: 1px solid #ccc;
  }
  .input-img {
    width: 30px;
    position: absolute;
    top: 4px;
    left: 3px;
  }
  .pro-but {
    width: 83px;
    height: 38px;
    line-height: 36px;
    border: none;
    color: #ffffff;
    background-color: #e86300;
  }
  .product-con {
    width: 100%;
    /* height: 260px; */
    margin: 20px auto 0;
    background: #fff;
  }
  .con-cont {
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-between;
    padding: 15px;
  }
  .con-cont-left {
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* flex-wrap: wrap; */
  }
  .con-img {
    width: 45%;
    /* height: 185px; */
    position: relative;
  }
  .con-img-img{
    /* height: 185px;
    position: absolute;
    left: 0;
    top: 0; */
  }
  .img-text {
    position: absolute;
    top: 60px;
    right: 0px;
    padding: 6px 15px;
    background: linear-gradient(to right, #da3a02 0%, #ffffff 100%);
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    color: #fff;
  }
  .cont-left-text {
    width: 50%;
    /* margin-top: 40px; */
    text-align: left;
  }
  .left-text-tit {
    font-size:20px;
    cursor: pointer;
  }
  .tit-adress {
    width: 100%;
    height: 25px;
    line-height: 25px;
    text-align: center;
    padding: 5px 0;
    border-radius: 10px;
    background: #eeeeee;
  }
  .tit-sum {
    width: 100%;
    line-height: 20px;
    border-top: 2px dotted #eee;
    margin-top: 10px;
    padding-top: 10px;
    font-size: 14px;
  }
  .tag-content{
    display: flex;
    flex-wrap: wrap;
  }
   .tag-sty{
    color: #849aae;
    font-size: 12px;
    line-height: 25px;
    padding: 0 2px;
    margin: 3px 3px 3px 0;
    background-color: #f4f7f9;
  }
  .con-cont-right {
    display: none;
  }
  .right-price {
    text-align: left;
    font-size: 20px;
    color: #ec6c00;
  }
  .right-price span {
    font-size: 16px;
    color: #ec6c00;
  }
  .right-but {
    width: 110px;
    margin-top: 10px;
    height: 30px;
    border: none;
    border-radius: 25px;
    background-color: #545454;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    color: #fff;
  }
  .common-page {
    display: none;
    width: 100%;
    margin: auto;
    position: relative;
    height: 150px;
  }
  .page-con {
    width: 100%;
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
  }
  .loading-more{
    display: block;
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: red;
    cursor: pointer;
  }
}
</style>