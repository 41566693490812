<template>
  <div>
    <div class="product-con">
      <div class="product-tit-left">
        <div class="left-font">{{ communityName }} <span>[司法拍卖]</span></div>
        <!-- <div class="product-tit-right">400-000-000</div> -->
      </div>
      <div class="product-content">
        <div  class="content-img">
          <div class="swiper-container gallery-top" v-if="imgList.length > 0">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in imgList"
                :key="index"
              >
                <img style="width: 100%" :src="item.fileUrl" alt="" />
              </div>
            </div>
            <!-- Add Arrows -->
            <div class="swiper-button-next swiper-button-white"></div>
            <div class="swiper-button-prev swiper-button-white"></div>
          </div>
          <div
            class="swiper-container gallery-thumbs"
            v-if="imgList.length > 0"
          >
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in imgList"
                :key="index"
              >
                <img style="width: 100%" :src="item.fileUrl" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div>
           <div class="common-share share-style" @click="shareCode">
            <img class="common-share" src="@/assets/img/share.png" alt="">
            <span class="common-share" >分享</span>
          </div>
          <div class="right-common">
            <span class="common-sty">起拍价：</span>
            <span class="right-price">{{ price }}</span>
            <span>万元</span>
          </div>
          <div class="right-common">
            <span class="common-sty">评估价：</span>
            <span style="text-decoration:line-through;">{{ evaluation }}</span>
            <span>万元</span>
          </div>
          <div class="right-common">
            <span class="common-sty">保证金：</span>
            <span >{{ bond }}</span>
            <span>万元</span>
          </div>
          <div class="right-common">
            <span class="common-sty"> 加价幅度：</span>
            <span >{{ increaseRange }}</span>
            <span>万元</span>
          </div>
          <div class="cont-right-sum right-common">
            <div class="sum-common">
              <div class="common-sty">{{ area }}㎡</div>
              <div>建筑面积</div>
            </div>
            <div class="sum-common two-detail">
              <div class="common-sty">{{ totalFloor }}层</div>
              <div>总高</div>
            </div>
            <div class="sum-common">
              <div class="common-sty">{{ finishTime }}</div>
              <div>竣工时间</div>
            </div>
          </div>
          <div class="cont-right-detail right-common">
            <div>
              <div>
                <span class="common-sty">区域：</span><span>{{ county }}</span>
              </div>
              <div class="right-common">
                <span class="common-sty">单价：</span
                ><span>{{ unitPrice }} 元/㎡</span>
              </div>
              <div class="right-common">
                <span class="common-sty">户型：</span><span>/</span>
              </div>
            </div>
            <div>
              <div>
                <span class="common-sty">板块：</span><span>{{ cdb }}</span>
              </div>
              <div class="right-common">
                <span class="common-sty">类型：</span
                ><span>{{ houseType }}</span>
              </div>
              <div class="right-common">
                <span class="common-sty">拍卖截止日：</span
                ><span>{{ saleEndTime }}</span>
              </div>
            </div>
          </div>
          <div class="right-common">
              <span class="common-sty">地址：</span
              ><span>{{ address }}</span>
            </div>
           <div v-show="showQrCode" class="popContainer">
             <div class="qrCode-sty">
              <qr-code :codeId="id" @changeCode='closeCode'></qr-code>
             </div>
           </div>
        </div>
      </div>
    </div>
    <!-- <div class="product-con product-details">
      <div class="details-sty" id="community">
        <a @click="goUrl"><li class="common-con community">小区介绍</li></a>
        <a @click="goUrl2"><li class="common-con Surrounding">周边配套</li></a>
      </div>
      <div class="community-con">
        <div class="com-details">小区介绍</div>
        <div class="com-details" v-html="houseDesc"></div>
      </div>
    </div> -->
    <div class="product-con product-details" id="Surrounding">
      <iframe
        ref="frame"
        id="iframe"
        @load="sendMessage"
        src="map.html"
        scrolling="no"
        style="width: 100%; height: 660px"
        frameborder="0"
      ></iframe>
      <!-- <surroundingMap  :address='address'></surroundingMap> -->
    </div>
  </div>
</template>
<script>
import Swiper from "swiper/swiper-bundle.min.js";
import "swiper/swiper.min.css";
import qrCode from '../../components/qrCode.vue';
// import GetMap from '@/view/details/surroundingMap'
// import surroundingMap from './surroundingMap'
export default {
  components: { qrCode },
  // components:{surroundingMap},
  data() {
    return {
      price: "",
      area: "",
      evaluation:'',
      bond:'',//保证金
      increaseRange:'',//加价幅度
      totalFloor: "",
      finishTime: "",
      address: "",
      county: "",
      unitPrice: "",
      houseType: "",
      cdb: "",
      saleEndTime: "",
      houseDesc: "",
      id: this.$route.query.id,
      imgList: [],
      showQrCode:false,
      communityName:''
    };
  },
  mounted() {
    this.getData();
    document.addEventListener("click",(e)=>{
        let className=e.target.className
        if(className!='qrCode-sty' && className!='common-share'){
            this.showQrCode=false
        }
    })
  },
  created() {
    this.getImg();
    // this.wxshare()
  },
  methods: {
     wxshare () {
       let _this=this
        // const link_urls = '*******' // 自定义分享的链接
       // const urls = location.href.split('#')[0]  // 动态获取当前页面链接,用于向后端获取签名
        let datas = 'https://dtfcloud.com/#/index'

        _this.$http.post(this.$api.wxshare+datas).then(res => {debugger // 这是向后端发的请求,返回微信分享接口需要的签名
          _this.$wx.config({
            debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.data.appId, // 必填，公众号的唯一标识
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名
            jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
          })
          _this.$wx.ready(function () {debugger // 需在用户可能点击分享按钮前就先调用
           //分享到朋友圈
                _this.$wx.onMenuShareTimeline({
                  title: 'tit',   // 分享时的标题
                  link: 'https://dtfcloud.com/#/index',     // 分享时的链接
                  imgUrl: 'https://dtfcloud.com/skjy/6a6bacfa-fb82-461c-9cf8-b8d166f06fec.png',    // 分享时的图标
                  success: function () {
                    console.log("分享成功");
                  },
                  cancel: function () {
                    console.log("取消分享");
                  }
                });
                //分享给朋友
                _this.$wx.onMenuShareAppMessage({
                  title: 'tit',
                  desc: '这件商品终于优惠了，每件只需'+'元',
                  link: 'https://dtfcloud.com/#/index',
                  imgUrl:'https://dtfcloud.com/skjy/6a6bacfa-fb82-461c-9cf8-b8d166f06fec.png',
                  type: '',
                  dataUrl: '',
                  success: function () {
                    console.log("分享成功");
                  },
                  cancel: function () {
                    console.log("取消分享");
                  }
                });
          })
        _this.$wx.error(function () {
        })
      })
      },
    //分享
    shareCode(){
      this.showQrCode=true
    },
    closeCode(){
      this.showQrCode=false
    },
    goUrl() {
      document.querySelector("#community").scrollIntoView(true);
    },
    goUrl2() {
      document.querySelector("#Surrounding").scrollIntoView(true);
    },
    initSwiper() {
      this.$nextTick(() => {
        var galleryThumbs = new Swiper(".gallery-thumbs", {
          spaceBetween: 10,
          slidesPerView: 4,
          freeMode: true,
          watchSlidesVisibility: true,
          watchSlidesProgress: true,
        });
        new Swiper(".gallery-top", {
          spaceBetween: 10,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          thumbs: {
            swiper: galleryThumbs,
          },
        });
      });
    },
    getData() {
      this.$http
        .get(this.$api.detailsHoues, { hid: Number(this.id) })
        .then((res) => {
          if (res.data == null) {
            this.$myMsg.notify({ content: '房屋不存在', type: 'success', time: 1500, noNotifyBtn: false })
            this.$router.push('/');
            return
          }
          this.price = res.data.price;
          this.area = res.data.area;
          this.totalFloor = res.data.totalFloor;
          this.finishTime = res.data.finishTime;
          this.unitPrice = res.data.unitPrice;
          this.address = res.data.address;
          this.communityName = res.data.communityName;
          this.county = res.data.county;
          this.houseType = res.data.houseType;
          this.cdb = res.data.cdb;
          this.saleEndTime = res.data.saleEndTime;
          this.houseDesc = res.data.houseDesc;
          this.evaluation=res.data.evaluation
          this.bond=res.data.bond
          this.increaseRange=res.data.increaseRange
          // console.log(this.address)
          this.sendMessage();
        });
    },
    getImg() {
      this.$http.get(this.$api.getImg, { hid: Number(this.id) }).then((res) => {
        let dataList = res.data;
        dataList.map((x) => {
          if (x.type == 2) {
            // let img=x.fileUrl
            let imgDta = {};
            imgDta.fileUrl = x.fileUrl;
            this.imgList.push(imgDta);
          }
        });
        this.initSwiper();
      });
    },
    sendMessage() {
      let iframeWindow = document.querySelector("#iframe").contentWindow;
      iframeWindow.postMessage(this.address, "*"); // 发送消息至html页面
    },
  },
};
</script>
<style scoped>
div.popContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
.qrCode-sty{
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 60px;
  border-radius: 5px;
}
@media screen and (min-width: 750px) and (max-width: 1920px) {
  /* 分享 */
  .share-style{
    width: 70px;
    display: flex;
    margin-top: 14px;
    cursor: pointer;
  }
  .share-style:hover{
    color: #e86300;
  }
  .share-style img{
    width: 24px;
    margin-right: 2px;
  }

  .product-con {
    width: 1190px;
    margin: auto;
    background: #fff;
    /* border: 1px solid; */
  }
  .product-content,
  .product-tit-left {
    padding: 30px 40px;
    display: flex;
    justify-content: space-between;
  }
  .content-img{
    width: 500px;
  }
  .product-tit-left {
    line-height: 70px;
    margin-top: 25px;
    border-bottom: 1px solid #e86300;
  }
  .left-font {
    font-size: 22px;
    font-weight: bold;
  }
  .left-font span {
    color: #999;
  }
  .product-tit-right {
    font-size: 30px;
    color: #e86300;
    font-weight: bold;
  }
  .product-content {
    text-align: left;
    margin-left: 40px;
  }
  .cont-right-sum {
    display: flex;
    padding: 20px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  .sum-common {
    padding: 0 40px;
  }
  .two-detail {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }
  .cont-right-detail {
    display: flex;
    justify-content: space-between;
  }

  .right-common {
    margin-top: 10px;
    font-size: 15px;
    color: #54575e;
  }
  .common-sty {
    font-weight: bold;
  }
  .right-price {
    color: #e86300;
    font-size: 30px;
    font-weight: bold;
  }
  .price-old {
    text-decoration: line-through;
  }
  .details-sty {
    width: 1190px;
    height: 80px;
    line-height: 80px;
    /* padding: 0 40px; */
    display: flex;
    list-style: none;
    border-bottom: 1px solid #ddd;
  }
  .product-details {
    margin: 30px auto;
    padding-bottom: 20px;
    border-radius: 5px;
  }
  .details-sty a {
    text-decoration: none;
    color: #000;
  }
  .common-con {
    margin-left: 40px;
    font-size: 17px;
    font-weight: bold;
  }
  .details-sty li:hover {
    color: #e86300;
  }
  /* .Surrounding{
  margin-left: 30px;
} */
  .community-con {
    /* border: 1px solid; */
    margin: 20px 40px 0 40px;
    background: #f6f6f6;
  }
  .content-tit {
    margin-left: 40px;
    font-size: 16px;
    font-weight: bold;
    color: #e86300;
  }
  .com-details {
    padding: 25px;
  }
 .com-details >>> img{
    width: 100%;
  }
  .swiper-container {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-slide {
    background-size: cover;
    background-position: center;
  }

  .gallery-top {
    height: 75%;
    width: 100%;
  }

  .gallery-thumbs {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
  }

  .gallery-thumbs .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }

  .gallery-thumbs .swiper-slide-thumb-active {
    opacity: 1;
  }
}
@media screen and (min-width: 10px) and (max-width: 750px) {
   /* 分享 */
  .share-style{
    display: none;
  }
  /*  */
  .product-con {
    width: 100%;
    margin: auto;
    background: #fff;
    /* border: 1px solid; */
  }
  .product-content,
  .product-tit-left {
    padding: 10px;
    /* display: flex;
    justify-content: space-between; */
  }
  .content-img{
    width: 100%;
  }
  .product-tit-left {
    line-height: 35px;
    margin-top: 25px;
    border-bottom: 1px solid #e86300;
  }
  .left-font {
    font-size: 17px;
    font-weight: bold;
  }
  .left-font span {
    color: #999;
  }
  .product-tit-right {
    font-size: 30px;
    color: #e86300;
    font-weight: bold;
  }
  .product-content {
    text-align: left;
    /* margin-left: 40px; */
  }
  .cont-right-sum {
    display: flex;
    justify-content: space-around;
    padding: 20px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  .sum-common {
    /* padding: 0 40px; */
  }
  /* .two-detail {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  } */
  .cont-right-detail {
    /* display: flex;
    justify-content: space-between; */
  }

  .right-common {
    width: 100%;
    margin-top: 10px;
    font-size: 15px;
    color: #54575e;
  }
  .common-sty {
    font-weight: bold;
  }
  .right-price {
    color: #e86300;
    font-size: 30px;
    font-weight: bold;
  }
  .price-old {
    text-decoration: line-through;
  }
  .details-sty {
    width: 1190px;
    height: 80px;
    line-height: 80px;
    /* padding: 0 40px; */
    display: flex;
    list-style: none;
    border-bottom: 1px solid #ddd;
  }
  .product-details {
    margin: 30px auto;
    padding-bottom: 20px;
    border-radius: 5px;
  }
  .details-sty a {
    text-decoration: none;
    color: #000;
  }
  .common-con {
    margin-left: 40px;
    font-size: 17px;
    font-weight: bold;
  }
  .details-sty li:hover {
    color: #e86300;
  }
  /* .Surrounding{
  margin-left: 30px;
} */
  .community-con {
    /* border: 1px solid; */
    margin: 20px 40px 0 40px;
    background: #f6f6f6;
  }
  .content-tit {
    margin-left: 40px;
    font-size: 16px;
    font-weight: bold;
    color: #e86300;
  }
  .com-details {
    padding: 25px;
  }
  .com-details >>> img{
    width: 100%;
  }

  .swiper-container {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-slide {
    background-size: cover;
    background-position: center;
  }

  .gallery-top {
    height: 75%;
    width: 100%;
  }

  .gallery-thumbs {
    height: 20%;
    box-sizing: border-box;
    padding: 10px 0;
  }

  .gallery-thumbs .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }

  .gallery-thumbs .swiper-slide-thumb-active {
    opacity: 1;
  }
}
</style>
