<template>
  <transition name="slide-fade">
    <div class="my-notify"
         v-if="notifyFlag">
      <div class="notify success"
           v-if="type=='success'">
        <i class="el-icon-success"></i>
        <span class="content"> {{ content }}</span>
        <div v-if="noNotifyBtn"
             class="noNotifyAgain">
          <span @click="noAgainFun">{{ noRemind }}</span>
        </div>
      </div>
      <div class="notify message"
           v-else-if="type=='message'">
        <i class="el-icon-info"></i>
        <span class="content">{{ content }}</span>
        <div v-if="noNotifyBtn"
             class="noNotifyAgain">
          <span @click="noAgainFun">{{ noRemind }}</span>
        </div>
      </div>
      <div class="notify error"
           v-else-if="type=='error'">
        <i class="el-icon-error"></i>
        <span class="content">{{ content }}</span>
        <div v-if="noNotifyBtn"
             class="noNotifyAgain">
          <span @click="noAgainFun">{{ noRemind }}</span>
        </div>
      </div>
      <div class="notify warning"
           v-else-if="type=='warning'">
        <i class="el-icon-warning"></i>
        <span class="content">{{ content }}</span>
        <div v-if="noNotifyBtn"
             class="noNotifyAgain">
          <span @click="noAgainFun">{{ noRemind }}</span>
        </div>
      </div>
      <!-- 可以简写如下 -->
      <!-- <div class="notify"
           :class="[type === 'success' ? 'success' : (type === 'error' ? 'error' : (type === 'warning' ? 'warning' : 'message')), noNotifyBtn ? 'notifyPadding' : '']">
        <i :class="[type === 'success' ? 'el-icon-success' : (type === 'error' ? 'el-icon-error' : (type === 'warning' ? 'el-icon-warning' : 'el-icon-info'))]"></i>
        <span class="content"> {{content}}</span>
        <div v-if="noNotifyBtn"
             class="noNotifyAgain">
          <span @click="noAgainFun">{{noRemind}}</span>
        </div>
      </div> -->
    </div>
  </transition>
</template>

<script>
export default {
  name: 'notifyMessage',
  props: {},
  components: {},
  data() {
    return {
      noRemind: 'Dont remind again'
    }
  },
  created() {
  },
  mounted() {
  },
  watch: {},
  computed: {},
  methods: {
    noAgainFun() {
      sessionStorage.setItem('dontRemindAgain', '1')
    }
  }
}
</script>

<style scoped>
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.notify-wrap {
  background-color: #edf2fc;
}

.my-notify {
  margin: 10px;
  width: 500px;
}

.notify {
  padding: 15px 15px;
  border-radius: 4px;
  background-color: rgb(255, 244, 224);
  box-shadow: -5px 5px 12px 0 rgba(204, 204, 204, 0.8);
  animation: show cubic-bezier(0.18, 0.89, 0.32, 1.28) 0.4s;

i {
  font-size: 16px;
  font-weight: 600;
  margin-right: 5px;
}

.content {
  font-size: 14px;
  word-break: break-all;
  word-wrap: break-word;
  line-height: 20px;
}

}

.notifyPadding {
  padding: 15px 15px 10px 15px;
}

.message {
  background-color: #edf2fc;

i,
.content {
  color: #909399;
}

}

.success {
  background-color: #f0f9eb;

i,
.content {
  color: #67c23a;
}

}

.error {
  background-color: #fef0f0;

i,
.content {
  color: #f56c6c;
}

}

.warning {
  background-color: #fdf6ec;

i,
.content {
  color: #e6a23c;
}

}

.noNotifyAgain {
  width: 100%;
  text-align: right;

span {
  font-size: 12px;
  color: rgb(204, 201, 201);
  border-bottom: 1px solid rgb(204, 201, 201);
  cursor: pointer;

&
:hover {
  color: #001a70;
  border-bottom: 1px solid #001a70;
}

}
}

@keyframes show {
  0% {
    right: -350px;
  }
  100% {
    right: 10px;
  }
}
</style>
