<template>
  <div>
    <div class="foot">
      <!-- <ul class="foot-ul">
                <li :class="index==6?'':''" v-for='(item,index) in liList' :key="index">
                    {{item.name}}
                    <span :class="index==6?'li-span last-span':'li-span'"></span>
                </li>
            </ul> -->
      <!-- <div class="foot-img">
                <img style="width:100%;" src="../../static/img/4.png" alt="">
            </div> -->
      <div class="text-sty" style="padding-top: 40px">
        地址：上海市浦东新区峨山路91弄98号陆家嘴软件园1号楼4F-6F
      </div>
      <div class="text-sty text-sty2">版权所有 重庆数科金云科技有限公司</div>
      <div class="text-sty">备案号：2021002200号</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped>
@media screen and (min-width: 750px) and (max-width: 1920px) {
  .foot {
    width: 100%;
    height: 150px;
    background: #302d28;
    color: #fff;
  }
  .foot-ul {
    width: 1190px;
    margin: auto;
    padding-top: 40px;
    display: flex;
    justify-content: center;
    list-style: none;
    font-size: 14px;
    cursor: pointer;
  }
  /* .foot-ul li:hover{
    color: coral;
} */
  /* .li-span{
    width: 1px;
    height: 2px;
    margin: 0 40px;
    border: 1px solid #797777;
    background: #797777;
} */
  /* .last-span{
    display: none;
} */
  /* .foot-img{
    width: 175px;
    margin: 40px auto 10px;
} */
  .text-sty {
    font-size: 12px;
    color: #ccc;
  }
  .text-sty2 {
    padding: 5px 0;
  }
}
@media screen and (min-width: 10px) and (max-width: 750px) {
  .foot {
    width: 100%;
    height: 150px;
    background: #302d28;
    color: #fff;
  }
  .foot-ul {
    width: 1190px;
    margin: auto;
    padding-top: 40px;
    display: flex;
    justify-content: center;
    list-style: none;
    font-size: 14px;
    cursor: pointer;
  }
  /* .foot-ul li:hover{
    color: coral;
} */
  /* .li-span{
    width: 1px;
    height: 2px;
    margin: 0 40px;
    border: 1px solid #797777;
    background: #797777;
} */
  /* .last-span{
    display: none;
} */
  /* .foot-img{
    width: 175px;
    margin: 40px auto 10px;
} */
  .text-sty {
    font-size: 12px;
    color: #ccc;
  }
  .text-sty2 {
    padding: 5px 0;
  }
}
</style>