import Vue from "vue";
import Router from "vue-router";
// import login from '@/view/login'
import content from '@/components/content'
import index from '@/view/details/index'
import productData from '@/view/details/productData'
import home from '@/view/map/home'
// import qrCode from '@/components/qrCode'

Vue.use(Router)
const router = new Router({
    routes:[
      {
        path:'/',
        name:'content',
        // meta: {
        //   requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
        // },
        component:content,
        children:[
            {
                path:'/',
                name:'index',
                // meta: {
                //   requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
                // },
                component:index
            },
            {
                path:'/index/productData',
                name:'productData',
                component:productData,
                // meta: {
                //     requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
                //   },
            },
           
        ]
      },
      {
        path:'/home',
        name:'home',
        component:home,
      },
      // {
      //   path:'/qrCode',
      //   name:'qrCode',
      //   component:qrCode,
      // },
    ]
    // routes:[
    //     {
    //         path:'/',
    //         name:'login',
           
    //         component:login
    //     },
    //     {
    //         path:'/index',
    //         name:'content',
    //         // meta: {
    //         //   requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
    //         // },
    //         component:content,
    //         children:[
    //             {
    //                 path:'/index',
    //                 name:'index',
    //                 // meta: {
    //                 //   requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
    //                 // },
    //                 component:index
    //             },
    //             {
    //                 path:'/index/productData',
    //                 name:'productData',
    //                 component:productData,
    //                 meta: {
    //                     requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
    //                   },
    //             },
    //         ]
           
    //     }
        
    // ]
});
// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
  let flag=sessionStorage.getItem('token');
  if(to.meta.requireAuth==true){
    if(!flag){
      next({
      path:'/',
      })
      }else{
        return next()
      }
    }else{
      return next()
      }
  });

  
export default router;

