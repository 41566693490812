const baseUrl='/judicial-api'
const url={
    login:baseUrl+'/login/userLogin3',//登录
    getCode:baseUrl+'/verificationcode/saveVerificationCode',//获取验证码
    housesUrl:baseUrl+'/showDicts',//房屋信息查询
    detailsHoues:baseUrl+'/showAuctionDesc2',//房屋详情
    getImg:baseUrl+'/showAuctionPicture',//房屋详情图片查询
    qwafcj:baseUrl+'/consult/insert',//留言
    showCounty:baseUrl+'/showCounty',//区域
    countClick:baseUrl+'/house/click',//统计点击量
    wxshare:'https://dtfcloud.com/gateway-api/activities/card/signInfo?url=',
    // wxshare:'http://dtfcloud.com:8010/gateway-test-api/activities/card/signInfo?url=',
    // qrCode:'http://dtfcloud.com:8012/#/index/productData?id=',//二维码地址
    //qrCode:'http://ccz.dtfcloud.com/#/index/productData?id='//二维码地址
    
}
export default url
