<template>
  <div>
    <div class="header">
      <div class="header-img">
        <img @click="check" class="logo-img"  src="../assets/img/logo.png" alt="" />
      </div>
      <ul class="header-ul">
        <li class="header-li" @click="check">首页</li>
        <!-- <li
          :class="clickLi == item.path ? 'header-li check-li' : 'header-li'"
          @click="check(item,index)"
          v-for="(item, index) in navList"
          :key="index"
        >
        {{item.name}}
        </li> -->
      </ul>
      <!-- <ul class="header-ul">
        <li class="header-li" @click="personal">个人中心</li>
      </ul> -->
    </div>
    <div class="bgImg">
      <!-- <img style="width: 100%;" src="@/assets/img/banner1.png" alt=""> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      clickLi: 0,
      isShow: true,
      // navList: [
      //   { name: "司法拍卖" ,path:'/index'},
      //   { name: "司法拍卖1",path:'/index1'},
      //   { name: "司法拍卖2" ,path:'/index2'},
      //   { name: "司法拍卖3",path:'/index3' },
      //   { name: "司法拍卖4" ,path:'/index4'},
      // ],
    };
  },
  mounted() {
    // this.getRoute()
  },
  watch: {
    $route() {
      // this.getRoute()
    },
  },
  methods: {
    // getRoute(){
    //   let pathName=this.$route.path
    //   this.navList.map(x=>{
    //     this.isShow=true
    //     if(pathName.indexOf(x.path)>=0){
    //       this.clickLi=x.path
    //     }
    //     if(pathName.indexOf('details')>=0){
    //       this.isShow=false
    //     }
    //   })
    // },
    // check(item,index) {
    //   this.clickLi = item.path;
    //   this.$router.push(item.path)
    //   console.log(index);
    // },
    personal(){
      this.$router.push("/view/details/personal");
    },
    check() {
      if(this.$route.path!="/"){
        this.$router.push("/");
      }
    },
  },
};
</script>
<style scoped>
@media screen and (min-width: 750px) and (max-width: 1920px) {
  .isshowww{
    position: fixed;
    top: 50px;
    right: 0;
    z-index: 999;
  }

  /*  */
  .header {
    width: 1190px;
    height: 80px;
    margin: auto;
    display: flex;
    justify-content: flex-start;
    /* border: 1px solid; */
  }
  .header-img {
    display: flex;
    align-items: center;
  }
  .header-ul {
    list-style: none;
    display: flex;
    line-height: 80px;
  }
  .header-li {
    margin-left: 50px;
    font-size: 30px;
    cursor: pointer;
    color: #e86300;
    /* border-bottom: 1px solid; */
  }
  .logo-img{
    width: 180px;
    cursor: pointer;
  }
  /* .check-li {
  border-bottom: 1px solid;
} */
.bgImg{
  width: 100%;
  height: 300px;
  background:url("../assets/img/banner.png") no-repeat;
  background-size: 100% 100%;
}
}
@media screen and (min-width: 10px) and (max-width: 750px) {

  /*  */
  .header {
    width: 90%;
    height: 80px;
    margin: auto;
    display: flex;
    justify-content:space-between;
    /* border: 1px solid; */
  }
  .header-img {
    display: flex;
    align-items: center;
  }
  .header-ul {
    list-style: none;
    display: flex;
    line-height: 80px;
  }
  .header-li {
    margin-left: 50px;
    font-size: 25px;
    cursor: pointer;
    color: #e86300;
    /* border-bottom: 1px solid; */
  }
  .logo-img{
    width: 120px;
    cursor: pointer;
  }
  /* .check-li {
  border-bottom: 1px solid;
} */
.bgImg{
  width: 100%;
  height: 200px;
  background:url("../assets/img/banner1.png") no-repeat;
  background-size: 100% 100%;
}
}
</style>