import Vue from 'vue'
import App from './App.vue'
import router from './router'
import http from '@/util/http.js'
import api from '@/util/api'
import vueSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import { VueJsonp } from 'vue-jsonp'    // 网上很多博客引用不加{}，会报错
import BaiduMap from 'vue-baidu-map'
import wx from 'weixin-js-sdk';
import QRCode from 'qrcodejs2'
import notifyMessage from '@/components/message/Toast.js'

Vue.use(notifyMessage.register)
Vue.use(BaiduMap, {
  ak: 'iBOloaHLkbnQcRMVFUyM9CQyA2jw6tXb'
})

Vue.prototype.$wx=wx
Vue.use(VueJsonp)
Vue.use(vueSwiper)
Vue.prototype.$http=http //axios请求
Vue.prototype.$api=api //axios请求
Vue.config.productionTip = false
Vue.prototype.$qrCode = QRCode;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
