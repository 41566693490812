<template>

    <baidu-map class="map" :center="center" :zoom="zoom" :min-zoom="minzoom" :scroll-wheel-zoom='scrollwheelzoom'
        @ready="handler" @zoomend="syncCenterAndZoom">
        <!-- 比例尺 -->
        <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
        <!-- 地图类型 -->
        <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_LEFT"></bm-map-type>
        <!-- 区域气泡 -->
        <div v-if="showZone">
            <zone-overlay v-for="(item, index) in zoneGeoPoints" :key="index" :position="{lng: item.lng, lat: item.lat}"
                :text="item" @mouseover.native="selectZone(item, index)" @mouseleave.native="cancelZone"
                @click.native="handClick(item,index)">
            </zone-overlay>
        </div>
        <!-- 区域边界 -->
        <bm-boundary v-if="showBoundary" :name="zoneBoundary" :strokeWeight="2" strokeColor="blue" fillColor="skyblue"
            :fillOpacity="0.4"></bm-boundary>
        <div v-if="labelShow" :key="index">
            <!-- 定义点 -->
            <!-- <bm-marker :position="{lng: marker.lng, lat: marker.lat}">
                <bm-label
                    :content="marker.community"
                    :labelStyle="{  color: '#fff', background: '#3072f6', border:'#fff',left:'-15px',font:'14px'}"
                    :offset="{width: -35, height: 30}" />
            </bm-marker> -->
            <labelOVerlay v-for="(item, index) in markers" :key="index" :position="{lng: item.lng, lat: item.lat}"
                :text="item.community" @click.native="drawerClick(item,index)">
            </labelOVerlay>
        </div>
        <div class="showList">
            我来了！！！
            <span>{{formData.equNum}}</span>
           <!-- 抽屉展示详情 -->
            <!-- <el-drawer :title="title" :visible.sync="drawer" :direction="direction">
                垃圾站信息
                <el-tag>垃圾站信息</el-tag>
                <el-form ref="form" :model="formData" label-width="80px" label-position="top">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="设备编号">
                                <span>{{formData.equNum}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="城市">
                                <span>{{formData.city}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="地区">
                                <span>{{formData.area}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="街道">
                                <span>{{formData.street}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="经度">
                                <span>{{formData.lng}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="纬度">
                                <span>{{formData.lat}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="日期">
                                <span>{{formData.Date}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="姓名">
                                <span>{{formData.name}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="手机号">
                                <span>{{formData.mobile}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12"></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-tag>小区垃圾站下挂箱信息</el-tag>
                        </el-col>
                        <el-col :span="12">
                            <el-tag type="success" v-if="formData.status=='正常'">正常</el-tag>
                            <el-tag type="info" v-if="formData.status=='箱满'">箱满</el-tag>
                            <el-tag type="warning" v-if="formData.status=='设备故障'">设备故障</el-tag>
                            <el-tag type="danger" v-if="formData.status=='发生火警'">发生火警</el-tag>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="可回收/kg">
                                <span>{{formData.recycled}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="厨余/kg">
                                <span>{{formData.KitchenWaste}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="有害/kg">
                                <span>{{formData.harmful}}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="其他/kg">
                                <span>{{formData.other}}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-drawer> -->
        </div>
    </baidu-map>
</template>
<script>
    import { zoneGeoPoints, garbageStation, VillageInfo } from "@/util/home";
    import zoneOverlay from './zoneOverlay.vue'
    import labelOVerlay from './labelOVerlay.vue'
    export default {
        components: {
            zoneOverlay,
            labelOVerlay
        },
        data() {
            return {
                title: '',
                direction: 'rtl',
                // drawer: false,
                innerDrawer: false,
                active: false,
                center: { lng: 119.967111, lat: 31.810886 },
                zoom: 12,
                minzoom: 12,
                scrollwheelzoom: true,
                zoneGeoPoints: null,
                showZone: true,
                zoneBoundary: null,
                showBoundary: false,
                markers: null,
                show: true,
                labelShow: false,
                formData: {}
            }
        },
        mounted() {
            this.zoneGeoPoints = zoneGeoPoints;
        },
        methods: {
            draw({ el, BMap, map }) {
                console.log(`el`, el)
                const pixel = map.pointToOverlayPixel(new BMap.Point(el.lng, el.lat))
                el.style.left = pixel.x - 60 + 'px'
                el.style.top = pixel.y - 20 + 'px'
            },
            handler({ BMap, map }) {
                console.log(BMap) // just console.log(BMap)
                console.log(map) // just console.log(map)
            },
            syncCenterAndZoom(e) {
                const ZOOMBOUNDARY = 13
                this.zoom = e.target.getZoom()
                this.showZone = this.zoom < ZOOMBOUNDARY

                if (e.target.getZoom() == 12) {
                    this.markers = null
                }
            },
            selectZone(item, index) {
                this.zoneBoundary = item.posCity + item.name + '' // 行政区名字，只供参考
                this.showBoundary = true
                console.log(index);
            },
            cancelZone() {
                this.zoneBoundary = ''
                this.showBoundary = false
            },
            handClick(item, index) {
                this.zoom = 15
                this.markers = garbageStation;
                this.center = { lng: item.lng, lat: item.lat }
                this.zoneBoundary = ''
                this.showBoundary = false
                this.labelShow = true
                 console.log(index);
            },
            infoWindowClose() {
                this.show = false
            },
            infoWindowOpen() {
                this.show = true
            },
            drawerClick(item, index) {
                console.log(`item`, item)
                console.log(`index`, index)
                // this.drawer = true
                this.title = item.community
                this.formData = item
                var VillageInfo_item = VillageInfo.filter(v => v.equNum == item.equNum)[0];
                console.log(`VillageInfo_item`, VillageInfo_item)
                this.formData.recycled = VillageInfo_item.recycled
                this.formData.KitchenWaste = VillageInfo_item.KitchenWaste
                this.formData.harmful = VillageInfo_item.harmful
                this.formData.other = VillageInfo_item.other
                this.formData.status = VillageInfo_item.status
                this.formData.statusColor = VillageInfo_item.statusColor
                console.log(`this.formData`, this.formData)
            },
            handleClose(done) {
                this.$confirm('还有未保存的工作哦确定关闭吗？')
                    .then(res => {
                        done();
                        console.log(res);
                    })
                    .catch(error=> { console.log(error);});
            }
        },
    }
</script>

<style>
    .map {
        width: 100%;
        height: 100vh;
    }
    .BMapLabel {
        border-radius: 8px;
        font-family: 'Courier New', Courier, monospace;
        letter-spacing: 0ch;
        line-height: 12px;
    }
    .sample {
        width: 120px;
        height: 40px;
        line-height: 40px;
        background: rgba(0, 0, 0, 0.5);
        overflow: hidden;
        box-shadow: 0 0 5px #000;
        color: #fff;
        text-align: center;
        padding: 10px;
        position: absolute;
    }
    .sample.active {
        background: rgba(0, 0, 0, 0.75);
        color: #fff;
    }
    .BMap_cpyCtrl {
        display: none;
    }
    .anchorBL {
        display: none;
    }
    .showList{
        width: 200px;
        height: 200px;
        position: absolute;
        top: 70px;
        color: blueviolet;
        background: #fff;
    }
</style>