<template>
  <div>
      <img @click="closeCode" class="code-img" src="@/assets/img/close.png" alt="">
      <div id="qrCode" ref="qrCodeDiv"></div>
      <div class="code-title">微信"扫一扫"分享好友</div>
  </div>
</template>

<script>
  import QRCode from 'qrcodejs2';
  export default {
    props:['codeId'],
    name: "qrCode",
    data() {
      return {}
    },
    mounted: function () {
      this.$nextTick(function () {
        this.bindQRCode();
      })
    },
    methods: {
        closeCode(){
            this.$emit('changeCode')
        },
      bindQRCode: function () {
        new QRCode(this.$refs.qrCodeDiv, {
        //   text: `http://172.16.8.189:8080/#/index/productData?id=${this.codeId}`,
          //测试环境地址
          text: `http://dtfcloud.com:8012/#/index/productData?id=${this.codeId}`,
          // 生产地址
          //text: `http://ccz.dtfcloud.com/#/index/productData?id=${this.codeId}`,
          width: 100,
          height: 100,
          colorDark: "#333333", //二维码颜色
          colorLight: "#ffffff", //二维码背景色
          correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
        })
      }
    }
  }
</script>
<style scoped>
.code-img{
    position: absolute;
    top: 5px;
    right: 5px;
}
.code-title{
    width: 100px;
    margin-top: 10px;
    text-align: center;
    font-size: 14px;
    color: #101d37;
}
</style>