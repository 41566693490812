<template>
    <div>
        <Header></Header>
        <div class="content">
             <router-view></router-view>
        </div>
        <suggestion></suggestion>
        <foot></foot>
    </div>
</template>
<script>
import Header from '@/components/header'
import Foot from '@/components/foot.vue'
import Suggestion from '@/components/suggestion.vue'

export default {
    data(){
        return{}
    },
    components:{
        Header,
        Foot,
        Suggestion
    }
}
</script>
<style scoped>
.content{
    background: #f6f6f6;
    overflow: hidden;
}
</style>